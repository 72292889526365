<template>

    <div class="backdrop">
     <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Time is up</h4>
          </div>
          
        </div>
        <div class="p-5">
          <div class="mx-auto">
            
            <h6 class="pt-2">Locked price has changed.</h6>
            <h6>You will be redirected to the homepage.</h6>
  
            <div class="d-flex justify-content-center mt-5 mb-2">
              

              
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'PriceReset',
          data(){
              return {
                  isEditing: false
              }
          },
          methods:{
            
            preventNav(event) {
                  if (!this.isEditing) return
                  event.preventDefault()
                  event.returnValue = ""
            },
          },

           beforeMount(){
            window.addEventListener("beforeunload", this.preventNav)
          },

           beforeDestroy() {
            window.removeEventListener("beforeunload", this.preventNav);
           
          },

          beforeRouteLeave(to, from, next) {
            if (this.isEditing) {
              if (!window.confirm("Leave without saving?")) {
                return;
              }
            }
            next();
          },
          mounted(){
            setTimeout(()=>{
              this.$router.push({name: 'Dashboard'})
            }, 5000)
          }

      }

  </script>
  <style scoped>
  .b-modal{
    width: 620px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
  }
.modal-header{
    width: 100%;
   background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }
  </style>