<template>
 <div class="main">
  <img :src="require('../assets/images/bg1.jpg')" class="bg" />
     <viewHeader/>

      <div class="body">
        <h1 class="my-5 text-white">My Cart</h1>
        <div class="container product-cart">
          <div class="d-modal row">
            <div class="cart-header modal-header">
              <div class="cart-title modal-title">
                <!-- <h2 class="text-white"> 00:{{ countdown }}</h2> -->
                <Timer/>
              </div>
            </div>
            <form v-if="hasPending">
                   <div class="pt-2 note"><h6 class="pt-2 note">You have a pending KYC request and it is under review. Please contact customer support at support@coexstar.com to complete this transaction. Thank you!</h6>
                  
                </div>
            </form>

            <form v-on:submit.prevent="proceedToCheckout()">
              <div v-if="cart" class="container mb-3 text-center">
                <div
                  class="row g-0 mx-auto d-flex justify-content-between cardCart"
                >
                  <div class="col-md-6 mt-2">
                    <img
                     :src="cart.image"
                      class="store-img"
                    />
                    <p class="cart-text text-center">
                      <small class="text-muted"
                        ><strong>{{cart.product}}</strong></small
                      >
                    </p>
                  </div>
                  <div class="col-md-6 card-body mt-2">
                    <h5 class="cart-text">Locked Price</h5>
                    <p class="cart-text">
                      <small class="text-muted cart-text"
                        >1 <strong>{{cart.product}}</strong> =
                        <strong class="cart-texts"> {{cart.toCurrency}} {{ formatAmount(cart.price)}}</strong></small
                      >
                    </p>
                  </div>
                </div>
                <div class="row g-0 mx-auto d-flex justify-content-between">
                  <div class="enterAmount text-center col-md-4 form-control">
                    <p class="danger" v-if="this.errors.amountToBuy != ''">{{errors.amountToBuy}}</p>
                    
                    
                    <h5 class="cart-text">
                      Enter amount to BUY in <strong> {{cart.crypto}}</strong
                      >:
                    </h5>
                    <p v-if="cart.fiat != 'PHP'">
                    Minimum amount required is equivalent to PHP 500 = {{cart.fiat}} {{convertedRatePhp}} = {{convertedRateCrypto}} {{cart.crypto}}.
                    </p>
                     <p v-if="cart.fiat == 'PHP'">
                    Minimum amount required is equivalent to PHP 500 = {{convertedRateCrypto}} {{cart.crypto}}.
                    </p>
                    <p>Maximum amount is equivalent to Php500,000.00 = {{convertedRateCryptoMax}} {{cart.crypto}}</p>
                      <small class="error-text">{{showTotalAmountError}}</small>
                    <input
                      v-model.number="amountToBuy"
                      type="text"
                      required
                      class="form-control text-center cart-text"
                      name="amountToBuy"
                      placeholder="Enter amount to buy"
                      maxlength="6"
                      @keypress="isNumber($event)"
                      @change="uponEnter($event)"
                   
                    />
                    <!-- <p class="cart-text text-center">
                      <small class="text-muted">Fee: {{cart.fee}} {{cart.crypto}}</small>
                    </p> -->
                  </div>
                  

                  <div class="col-md-5 card-body">
                    <h5 class="cart-text text-center">
                      You will receive in <strong>{{cart.crypto}}</strong
                      >:
                    </h5>
                     <h5 class="form-control cart-text"> {{amountToBuy}} </h5>
                    <p>
                      <small class="text-muted"></small>
                    </p>
                  </div>

                  <div class="col-md-2 text-center">
                    <img src="@/assets/images/arrow.png" class="store-img" />
                  </div>

                  <div class="col-md-5 card-body text-dark">
                    <h5 class="cart-text text-center">
                      You will pay this amount in <strong>{{cart.fiat}}</strong>:
                    </h5>
                    <h5 class="form-control cart-text"> {{formatAmount(totalAmountFiat)}}</h5>
                    
                    
                  </div>
                </div>

                <hr/>
              </div>
              <div v-if="isLoading">
                <b-icon
                  icon="arrow-clockwise"
                  animation="spin"
                  font-scale="4"
                ></b-icon
                >Getting your quote....
              </div>

              <div id="cart-buttons">
                <b-button class="btn btn-secondary mb-2 cancel" @click="exitModal">Cancel
                </b-button>
                <b-button class="btn btn-main mb-2 submit" type="submit" v-if="!hasPending && !notSubmitted" :disabled="isLoading"> 
                
                  Proceed to checkout
                </b-button>
              
               
                <b-button class="btn btn-primary mb-2 submit" v-if="hasPending ||  notSubmitted" @click="goToKyc()">
                    GO TO KYC
                </b-button>
              </div>
            </form>

            
          </div>
      </div>
      </div>
      <Exit @close="exitModal" v-if="showExitModal"/>
      <PriceReset v-if="lockedPriceReset"/>
      <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
      <Kyc v-if="completeKYC"/>
      <ContactUs @close="close" v-if="showContact" />

      </div>
    
</template>
<script>
      import numeral from 'numeral'
      import Exit from './modals/Exit.vue'
      import PriceReset from './modals/PriceReset.vue'
      import LogoutModal from './modals/LogoutModal.vue'
      import Timer from "./modals/Timer.vue"
      import Kyc from "./modals/Kyc.vue"
      import ContactUs from './modals/ContactUs.vue'
      import viewHeader from '../views/viewHeader.vue';


    export default {
     name: 'Cart',
          components: {
              viewHeader,
              Exit,
              PriceReset,
              Timer,
              LogoutModal,
              Kyc,
              ContactUs,
          },
          data(){
              return{
                  lockedPriceReset: false,
                  createdAt:'',
                  userId: '',
                  showCartModal: false,
                  cartTimerSeconds: 900,
                  amountToBuy: 0.05,
                  errors: {
                    amountToBuy:'',
                  },
                  showExitModal: false,
                  showTotalAmountError: '',
                  isEditing: false,
                  timer:'',
                  showLogoutModal: false,
                  completeKYC: false,
                  profile:{
                    mobileNumber:'',
                    middleName:'',
                    birthDate:'',
                    emailAddress:''

                  },
                  showContact: false,
                  status:'',
                  phpRate:'',
                  sourceCurrency: '',
                  convertedRatePhp:"",
                  convertedRateCrypto:"",
                  isLoading: false,
                  showSubmitted: false,
                  hasPending: false,
                  notSubmitted: false,
                  convertedRateCryptoMax: '',
                  countdown: 60,
                 
                  
                  

              }
          },
          computed: {
              init() {
                  return this.$store.state.init;
              },
               cart() {
                  return this.$store.state.cart;
                },
                cartItems() {
                  return this.$store.state.cartItems;
                },
                // cryptoToReceive: function () {
                //   return this.amountToBuy - this.cart.fee;
                // },
                totalAmountFiat: function () {
                  return this.amountToBuy * this.cart.price;
                 
                },
                
          },
           created: function(){
              let loaded = JSON.parse(localStorage.getItem('cart'));

              if(loaded) {
                this.cart.product = loaded.product.fromCurrency;
                this.cart.crypto = loaded.product.fromCurrency;
                this.cart.fiat = loaded.product.toCurrency;
                this.cart.image = loaded.image;
                this.cart.price = loaded.product.convertedAmount;
                // this.cart.fee = loaded.fee;
                }
               //console.log(localStorage.getItem('cart'))
            },

            methods:{
              goToKyc(){
                this.$router.push({name: 'Kyc'})
              },
            
                openLogout(){
                this.showLogoutModal = !this.showLogoutModal;
                },

                exitModal() {
                this.showExitModal = !this.showExitModal;
                },
                close() {
                this.showContact = !this.showContact;
                },
                getProfile: function(){
                  this.isLoading = true;
                    const options = {
                        method: 'GET',
                        headers: {  'content-type': 'application/x-www-form-urlencoded',
                        'blox-authz': localStorage.getItem("tokenId")},
                        url: '/cx/profile/',
                    };

                    this.axios(options)
                    .then((response) => {
                      this.isLoading = false

                      // setTimeout(() => {
                      //   this.isLoading = true
                      // },1000)
                       

                      //  console.log(response.data)
                        this.init.profile = response.data;
                        this.userId = response.data.entityId;
                        this.profile.middleName = response.data.middleName
                        this.profile.mobileNumber = response.data.mobilePhone
                        this.profile.emailAddress = response.data.emailAddress
                        this.profile.birthDate = response.data.birthDate
                        this.status = response.data.verificationStatus

                        if(response.data.pendingPoidCount > 0 && response.data.verificationStatus == 'UNVERIFIED'){
                            this.hasPending = true
                        }
                        if(response.data.verificationStatus == 'VERIFIED'){
                            this.hasPending = false
                        }
                         if(response.data.verificationStatus == 'UNVERIFIED' && response.data.pendingPoidCount == 0){
                            this.notSubmitted = true
                            
                        }

                        
                        })
                    
                },
              
                logout: function(){
                  this.axios.get('/cx/logout')
                  .then((response) => { 
                    
                    window.location.href = "/#/";
                      
                  }).catch((err) => { 
                 //   console.log(err)
                  })
                },
                formatAmount: function(amount) {
                    return numeral(amount).format('0,0.000000') 
                },
                clearError(){
                    this.showTotalAmountError = ""
                },
                
                 getPhpRate(){
                    this.isLoading = true;
                    const params = {
                            amount: this.totalAmountFiat,
                            fromCurrency: this.cart.fiat,
                            toCurrency: 'PHP'
                        }

                        const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');
                        

                                const options = {
                                method: 'POST',
                                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                data,
                                url: '/pub/getxchgrate',
                                };


                                this.axios(options)
                                .then((response) => {  
                               this.phpRate = response.data.convertedAmount
                               this.isLoading = false


                              //  console.log(response.data.convertedAmount)
                               
                                }).catch((err) => {

                        })
                    },
                
                getRate(){
                    this.isLoading = true;
                    const params = {
                            amount: 500,
                            fromCurrency: 'PHP',
                            toCurrency: this.cart.fiat,
                        }

                        const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');
                        

                                const options = {
                                method: 'POST',
                                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                data,
                                url: '/pub/getxchgrate',
                                };


                                this.axios(options)
                                .then((response) => {  
                                  this.isLoading = false
                               this.convertedRatePhp = response.data.convertedAmount
                               
                               
                                }).catch((err) => {

                        })
                    },
                    getCryptoRate(){
                    this.isLoading = true;
                    const params = {
                            amount: 500,
                            fromCurrency: 'PHP',
                            toCurrency: this.cart.crypto,
                        }

                        const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');
                        

                                const options = {
                                method: 'POST',
                                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                data,
                                url: '/pub/getxchgrate',
                                };


                                this.axios(options)
                                .then((response) => {  
                                  this.isLoading = false
                        //       console.log(response.data)
                               this.convertedRateCrypto = response.data.convertedAmount
                               
                               
                               
                                }).catch((err) => {

                        })
                    },
                    getCryptoRateMax(){
                    this.isLoading = true;
                    const params = {
                            amount: 500000,
                            fromCurrency: 'PHP',
                            toCurrency: this.cart.crypto,
                        }

                        const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');
                        

                                const options = {
                                method: 'POST',
                                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                data,
                                url: '/pub/getxchgrate',
                                };


                                this.axios(options)
                                .then((response) => {  
                                  this.isLoading = false
                          //     console.log(response.data)
                               this.convertedRateCryptoMax = response.data.convertedAmount
                               
                               
                               
                                }).catch((err) => {

                        })
                    },
                uponEnter(event){
                    this.amountToBuy = event.target.value;
                    this.getPhpRate();
                    this.getCryptoRate();
                },

                proceedToCheckout(){
                 let hasError = false;
                 if(!hasError ){
                 let myCartItems = {
                   product: this.cart.product,
                   fromCurrency: this.cart.crypto,
                   toCurrency: this.cart.fiat,
                   image: this.cart.image,
                   price: this.cart.price,
                   cryptoToReceive: this.amountToBuy,
                   cryptoInput: this.amountToBuy,
                   fee: this.cart.fee,
                   totalFiat: this.totalAmountFiat,
                   status: this.status
                   
                 };
                 localStorage.setItem('cartItems', JSON.stringify(myCartItems));
            //        console.log(myCartItems);
                    
                    if(this.phpRate < 500 ){
                      this.showTotalAmountError = "You have entered an amount lower than PHP 500"
                      hasError = true
                        setTimeout(()=>{
                           this.clearError()
                        },4000);
                    }
                  

                     if(this.phpRate > 500000){
                      this.showContact = true
                    }

                    if(this.status == 'UNVERIFIED' && response.data.pendingPoidCount == 0){
                        this.$router.push({name: 'Kyc'})
                    }
                    
                    else if(this.status === 'VERIFIED' && this.phpRate > 500 && this.phpRate < 499999.99){
                      this.$router.push({name: 'Checkout'})
              //         console.log(this.status)
                    }

                   history.pushState(null, null, location.href);
                    window.onpopstate = function () {
                        history.go(1);
                    };
                    }
                },
                isNumber: function(evt) {
                  evt = (evt) ? evt : window.event;
                  var charCode = (evt.which) ? evt.which : evt.keyCode;
                  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                  } else {
                    return true;
                  }
                   this.getPhpRate();
                  this.getCryptoRate();
                },
                  checkSession: function() {
                    this.isLoading = true;
                    const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    url: '/cx/sessioncheck',
                    };

                    this.axios(options)
                    .then((response) => { 
                        this.getProfile();
                        this.init.hasSession=true;
                        this.isLoading = false;
                        setTimeout( () => {
                            this.showLoading = false;
                        },1000)
                        }).catch((err) => {
                          this.init.hasSession=false;
                        if(err.response.data.msgCode == '00001') {
                            window.location.href = "/#/";
                        }  

                        if(err.response.data.msgCode == '00020') {

                            setTimeout( () => {
                            window.location.href = "/#/setup-profile";
                        },1000)
                            
                        }  

                        })
                },
                startTimer() {
                    this.timer = setInterval(() => {
                      this.countdown--;
                      if (this.countdown === 0) {
                        clearInterval(this.timer);
                        this.lockedPriceReset = true
                        setTimeout(()=>{
                          this.$router.push({name: 'Dashboard'})
                        },5000)
                      
                      }
                    }, 1000);
                  }
            },
            preventNav(event) {
                if (!this.isEditing) return
                event.preventDefault()
                event.returnValue = ""
           },
            beforeDestroy() {
              clearInterval(this.timer);
            },
            beforeMount(){
                this.checkSession();
            },
            mounted(){
                this.getRate();
                this.getPhpRate();
                this.getCryptoRate();
                this.getCryptoRateMax();
              //  this.startTimer();
               
            }

    
}
</script>
<style scoped>
.bg{
   width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }

 .main{
        height: 100vh !important;
        overflow-y: auto;
      }
  .red{
     color: red;
 }
 .disabled{
   color:rgba(57, 53, 53, 0.573) !important;
   cursor: none !important;
   border-bottom: none !important;
 }
 .a-text{
   cursor: pointer;
 }
 .a-text:hover{
   border-bottom: 1px solid rgb(14, 6, 81);
 }
      .body{
        margin-bottom: 100px !important;
        padding-bottom: 20px;
      }
      .d-modal{
        margin-bottom: 100px !important;
        padding-bottom: 20px;
      }
      .navbar{
        display: flex;
        padding: 20px 100px;
      }
      .product-cart{
        height: auto !important;
        margin-top: 50px;
        margin-bottom: 50px;
    }
      .logo{
          justify-content: center;
          text-align: center;
          display: flex !important;
      }
      .logo img{
          background-color: transparent;
          width: 7rem;
      }
     .store-img{
       width: 7rem;
     }
     .cart-text{
       font-size: 25px !important;
       color: black;
     }
     .error-text{
       font-size: 25px;
       color: red;
     }
     .input-text{
       font-size: 35px;
       color: black;
     }
      .card-cart {
        border: none;
        background: transparent;
        margin: 0 auto;
      }
      .cardCart {
        margin: 0 auto;
        align-content: center;
        text-align: center;
      }
      .amount{
        font-size: 20px;
        font-weight: bolder;
        border-bottom: 1px solid black;
      }

      .row.totalAmount {
        background: rgba(74, 149, 211, 0.938);
      }
      #cart-buttons {
        margin-left: 75%;
        display: flex;
        justify-content: space-around;
        text-decoration: none !important;
      }
      .cancel{
        color: black;
      }
      @media only screen and (max-device-width: 960px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
        .navbar{
          padding: 0 !important;
        }
        .store-img{
          width: 3rem;
        }
        form .text-center, .cart-text, .input-text {
          font-size: 20px;
        }
        .card-body{
          padding: 0 !important;
          margin: 0!important;
        }
        #cart-buttons{
          margin: 0 !important;
          margin-left: 0% !important;
          justify-content: center;
        }

      
      }

</style>