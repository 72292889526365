<template>

    <div class="backdrop">
      <div class="b-modal">
        <div class="modal-header">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Are you sure?</h4>
          </div>
          
        </div>
        <div class="p-5">
          <div class="mx-auto">
            
            <h6 class="pt-2">You have exceeded your maximum transaction limit.</h6>
            <h6>Please visit our OTC branch to complete our extensive KYC form or change the amount you are buying.</h6>
  
            <div class="d-flex justify-content-center mt-4 mb-4">
                <button class="btn btn-primary text-uppercase mr-sm-2" @click="closeModal">Stay</button>

              <button class="btn btn-secondary text-uppercase mr-sm-2" @click="goHome">Leave</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'ContactUs',
          data(){
              return {

              }
          },
          methods:{
            closeModal() {
                this.$emit("close");
            },
            goHome(){
                 this.$router.push({name: 'Dashboard', params: { userId: this.$route.params.userId}})
                 localStorage.removeItem("min", min);
                  localStorage.removeItem("sec", sec);
                  location.reload();
            }
          }

      }

  </script>
  <style scoped>
  .b-modal{
    width: 620px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
    background-image: linear-gradient(
    to right,
    #0f1878 0%,
    #0e144e 38%,
    #07045f 73%,
    #02033a 97%
  ) !important;
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
     top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }
  </style>