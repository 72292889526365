<template>
  <div>
    <p>{{ countdown }}</p>
    <PriceReset v-if="lockedPriceReset"/>
  </div>
  
</template>

<script>
  import PriceReset from '@/components/modals/PriceReset.vue'
export default {
  components:{
    PriceReset
  },
  data() {
    return {
      countdown: '01:00',
      lockedPriceReset: false
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const startTime = localStorage.getItem('countdownStartTime');
      if (startTime) {
        this.updateCountdown();
      } else {
        const currentTime = Math.floor(Date.now() / 1000);
        localStorage.setItem('countdownStartTime', currentTime);
        this.updateCountdown();
      }
    },
    updateCountdown() {
      const startTime = parseInt(localStorage.getItem('countdownStartTime'), 10);
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - startTime;
      const remainingTime = Math.max(60 - elapsedTime, 0);

      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;

      this.countdown = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;

      if (remainingTime > 0) {
        setTimeout(this.updateCountdown, 1000);
      } else {
        localStorage.removeItem('countdownStartTime');
        this.lockedPriceReset = true
       // setTimeout(()=>{
         // this.$router.push({name: 'Dashboard'})
       // },3000)
      }
    },
    formatTime(time) {
      return time.toString().padStart(2, '0');
    }
  }
};
</script>
